@font-face {
  font-family: "Raleway";
  src: url("https://blueprint.the-tractor.store/cdn/font/raleway-regular.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

html {
  font-family: Raleway, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
}

body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
}

p {
  line-height: 1.5;
}

:root {
  --outer-space: 1.5rem;
}

#app {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.loader-frame {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader {
  width: 48px;
  height: 48px;
  border: 3px dotted #FFF;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px dotted #FF3D00;
  border-style: solid solid dotted;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}
    
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
} 
